<template>
  <div class="w-full overflow-x-hidden">
    <Container>
      <Motion v-if="!isDesktop || cards.length > sliderDesktopBreakpoint"
              :initial="{ opacity: 0 }"
              :animate="{ opacity: [0, 1] }"
              :transition="{ delay: 0.2, duration: 0.6 }"
              class="flex-shrink-0"
      >
        <Slider>
          <CardBase v-for="(card, i) in cards"
                    :key="i"
                    :title="card.title"
                    :copy="card.copy"
                    :cta="card.cta"
                    :background-image="card.backgroundImage"
                    :background-image-mobile="card.backgroundImageMobile"
                    :type="cardType"
                    class="flex-shrink-0 keen-slider__slide"
                    :class="{
                      'xs:h-[460px]': cardType === 'image',
                      'max-xs:aspect-w-1 max-xs:aspect-h-1': cardType === 'news' || cardType === 'image',
                    }"
          />
        </Slider>
      </Motion>

      <div v-else
           class="grid gap-x-6"
           :class="{
             'grid-cols-2': sliderDesktopBreakpoint === 2,
             'grid-cols-3': sliderDesktopBreakpoint === 3,
             'grid-cols-4': sliderDesktopBreakpoint === 4,
           }"
      >
        <Motion v-for="(card, i) in cards"
                :key="i"
                :initial="{ opacity: 0 }"
                :animate="{ opacity: [0, 1] }"
                :transition="{ delay: 0.3 + 0.1 * i, duration: 0.6 }"
                class="flex-shrink-0"
        >
          <CardBase :title="card.title"
                    :copy="card.copy"
                    :cta="card.cta"
                    :background-image="card.backgroundImage"
                    :type="cardType"
                    :class="{
                      'xs:h-[460px]': cardType === 'image',
                      'max-xs:aspect-w-1 max-xs:aspect-h-1': cardType === 'news' || cardType === 'image',
                    }"
          />
        </Motion>
      </div>
    </Container>
  </div>
</template>

<script lang="ts" setup>
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Motion } from '@motionone/vue/dist/motion-vue.ssr.js'
import { useScreenSize } from '../composables/use-screen-size'
import type { CardType } from '../types/cards'

const { isDesktop } = useScreenSize()

type CardsType = {
  cards: Array<CardType>
  cardType?: 'news' | 'image'
  sliderDesktopBreakpoint?: number // At which length of cards, do we show a desktop slider
  sliderDesktop?: boolean
}

withDefaults(defineProps<CardsType>(), {
  cardType: 'image',
  sliderDesktopBreakpoint: 3,
  sliderDesktop: true
})
</script>
